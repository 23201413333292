$b: '.MuiAutocomplete';

#{$b} {
  &-endAdornment {
    top: 50% !important;
    transform: translateY(-50%);
  }

  &-option,
  &-noOptions {
    font-size: 1.5625rem;

    @include low-desktop {
      @include css-lock(1, 9, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(0.625, 6, $mobile-width, $tablet-width);
    }

    @include mobile {
      font-size: 0.625rem;
    }
  }

  &-popupIndicator {
    @include size(20px);
  }
}
