.how-to {
  padding-top: 15px;

  &__title {
    margin-top: 85px;
    font-size: 45px;
    line-height: 1;
    width: percentage(761 / $desktop-width);
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      margin-top: 24px;
      font-size: 24px;
      width: percentage(405 / $tablet-width);
    }

    @include mobile {
      width: 70%;
      font-size: 28px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    &:first-of-type {
      margin-top: 32px;
    }

    &:nth-of-type(2) {
      margin-top: 90px;

      @include tablet {
        margin-top: 16px;
      }

      @include mobile {
        flex-direction: column-reverse;
      }
    }
  }

  &__decor {
    pointer-events: none;
    user-select: none;
    width: 50%;
    flex-shrink: 0;
    align-self: flex-start;

    @include tablet {
      width: percentage(344 / $tablet-width);
    }

    @include mobile {
      width: 100%;
    }
  }

  &__text {
    font-size: 26px;
    margin-left: 1em;
    line-height: 1;
    padding-bottom: 36px;

    @include tablet {
      font-size: 19px;
      padding-top: 20px;
      padding-bottom: 0;
    }

    &._bottom {
      align-self: flex-end;
    }

    &._top {
      align-self: flex-start;
      padding-top: 24px;
    }

    &-item {
      margin-bottom: 48px;

      @include tablet {
        margin-bottom: 24px;
      }

      @include mobile {
        text-align: center;
      }
    }
  }
}
