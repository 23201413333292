$b: '.modal';

#{$b} {
  @include full-fix(0);
  background-color: rgba($black-true, 0.3);
  z-index: 1000;
  display: none;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    background-color: $white-true;
    max-height: 90vh;
    padding: 30px;
    box-shadow: 1px 1px 6px rgba($black-true, 0.25);
    border-radius: 5px;
    width: 100%;
    overflow-y: scroll;
  }

  &__close {
    width: 16px;
    position: absolute;
    right: 16px;
    top: 16px;

    svg {
      width: 100%;
    }
  }

  &__wrapper {
    overflow-y: auto;
  }

  &__footer {
    margin-top: 24px;
    font-size: 18px;
    text-align: center;

    @include tablet {
      margin-top: 16px;
      font-size: 14px;
    }

    @include mobile {
      margin-top: 12px;
      font-size: 12px;
    }

    &-link {
      color: $c-blue !important;
      transition: 0.25s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
