$b: '.median';

#{$b} {
  &-item {
    position: relative;
    padding: 16px 32px;
    background-color: $c-blue;
    min-height: 280px;
    width: 50%;
    box-shadow: 6px 2px 30px rgba(194, 192, 192, 0.5);
    flex-direction: column;
    border-radius: 18px;
    display: flex;

    @include low-desktop {
      padding: 16px 24px;
      min-height: 210px;
    }

    @include tablet {
      width: 100%;
    }

    @include mobile {
      min-height: 280px;
    }

    &__inner {
      display: flex;
      justify-content: space-around;
      gap: 5px;
      margin: auto 0;

      @include tablet {
        padding-left: 0;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 32px;

      @include low-desktop {
        gap: 16px;
      }
    }

    &__progress {
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      &-wrapper {
        width: 50%;
        position: relative;
        padding-top: 20px;

        @include low-desktop {
          padding-left: 0;
          padding-right: 0;
        }

        @include mobile {
          width: 100%;
          margin-bottom: 16px;
        }

        .progress-bar__fill {
          margin-left: auto;
          margin-right: auto;
          width: 90% !important;
        }
      }
    }

    &__salary {
      font-size: 55px;
      font-weight: 700;

      @include low-desktop {
        font-size: 36px;
      }

      @include tablet {
        font-size: 45px;
      }

      &-caption {
        font-size: 20px;

        @include low-desktop {
          font-size: 16px;
        }

        @include tablet {
          font-size: 16px;
        }

        @include mobile {
          font-size: 20px;
        }
      }
    }

    &__description {
      margin-top: auto;
      margin-bottom: auto;
      line-height: 1;
      font-size: 33px;

      @include low-desktop {
        font-size: 23px;
      }

      @include mobile {
        text-align: center;
        font-size: 22px;
      }
    }

    &__title {
      white-space: nowrap;
      font-size: 55px;
      margin-bottom: 23px;
      line-height: 0.9;
      font-weight: 700;

      @include low-desktop {
        font-size: 36px;
      }

      @include tablet {
        font-size: 55px;
      }

      @include mobile {
        font-size: 45px;
        text-align: center;
        white-space: normal;
      }
    }

    &__percentage {
      line-height: 0.8;
      font-size: 64px;
      font-weight: 700;

      @include low-desktop {
        font-size: 40px;
      }

      @include tablet {
        font-size: 55px;
      }

      @include mobile {
        font-size: 48px;
      }

      &-sm {
        font-size: 30px;
        line-height: 0.8;
        font-weight: 700;

        @include low-desktop {
          font-size: 25px;
        }

        @include mobile {
          font-size: 24px;
          max-width: min-content;
        }
      }
    }

    &__caption {
      font-size: 25px;

      @include tablet {
        font-size: 20px;
      }

      @include mobile {
        font-size: 25px;
      }
    }

    &__no-data {
      font-size: 33px;
      line-height: 1;
      font-weight: 500;
      text-align: center;
      margin: auto 0;

      @include low-desktop {
        font-size: 23px;
      }

      @include mobile {
        font-size: 22px;
      }
    }

    .info-hint path {
      fill: white;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: stretch;

    @include tablet {
      flex-direction: column;
    }
  }

  &-no-data {
    display: flex;
    align-items: center;
    background-color: #dfdfdf;
    padding: 24px 32px;
    border-radius: 18px;
    box-shadow: 6px 2px 30px rgba(194, 192, 192, 0.5);
    margin-top: 10px;
    font-size: 30px;
    color: white;
    line-height: 0.9;

    @include mobile {
      font-size: 15px;
      padding: 16px 20px;
    }
  }

  &-hint {
    width: 100%;
    top: 30px;
    min-width: 250px;
    left: -50%;
    transform: translateX(-80%) !important;
  }
}
