.info {
  &-date {
    align-self: flex-start;
    font-size: 1.25rem;
    line-height: 1.6;

    @include low-desktop {
      @include css-lock(0.8125, 7, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(0.625, 3, $mobile-width, $tablet-width);
      margin-top: 12px;
    }

    @include mobile {
      margin-top: 2px;
      font-size: 0.625rem;
    }
  }

  &-container {
    width: 415px;
    max-width: 40%;
    height: fit-content;
    position: absolute;
    top: 45%;
    right: 10%;
    transform: translateY(-45%);

    @include tablet {
      position: static;
      max-width: 70%;
      min-width: 290px;
      transform: none;
      margin: 18px auto 50px;
    }

    @include mobile {
      max-width: 100%;
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-content {
    position: relative;
    padding: 24px 28px 26px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: space-between;
    background: $white-true;
    box-shadow: 35px 23px 38px rgba(187, 188, 189, 0.6);
    border-radius: 18px;
    font-size: 1rem;
    line-height: 1.25;

    @include low-desktop {
      @include css-lock(0.625, 6, $tablet-width, $desktop-width);
    }

    @include tablet {
      padding: 34px 24px 24px;
      box-shadow: 23px 15px 25px rgba(187, 188, 189, 0.6);
      border-radius: 12px;
      font-size: 0.725rem;
    }

    @include mobile {
      //filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
    }
  }


  &-hint {
    position: absolute;
    top: 0.625em;
    right: 0.625em;
    width: 20px;
    height: 20px;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      width: 18px;
      height: 8px;
      transform: translate(-50%, 80%);
      background: img("arrow.svg") no-repeat center;
      display: none;
      background-size: cover;
    }

    &:hover::after {
      display: block;
    }

    &:hover .info-hint__text {
      display: block;
    }

    &__text {
      position: absolute;
      width: 20em;
      display: none;
      padding: 16px 18px;
      background: #D9D9D9;
      color: $black-true;
      border-radius: 12px;
      transform: translate(-200px, 4px);
      line-height: 1.29;
      z-index: 9999;
      font-size: 13px;

      @include tablet {
        padding: 8px 9px;
        border-radius: 12px;
        font-size: 0.625rem;
      }

      @include mobile {
        transform: translate(-80%, 4px);
      }
    }

    &__link {
      display: block;
      color: $c-blue !important;
      text-decoration: underline !important;
      margin-top: 1em;
    }
  }

  &-text {
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 24px;
    text-align: center;

    @include tablet {
      font-size: 0.661875rem;
    }

    @include mobile {
      font-size: 0.709375rem;
    }

    span {
      white-space: nowrap;
    }
  }

  &-changes {
    @include center-flex;
    width: 100%;
    flex: 1 1 auto;
    text-align: center;
    background: rgba(241, 241, 241);
    box-shadow: inset 0 3px 60px rgba($black-true, 0.1);
    border-radius: 6px;
    line-height: 0.91;
    padding: 20px 24px;
  }

  &-description {
    margin-top: 12px;
    padding: 16px 18px;
    background: #b0b0b0;
    border-radius: 17px;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.04;

    @include tablet {
      margin-top: 14px;
      padding: 8px 9px;
      border-radius: 12px;
      font-size: 0.625rem;
    }

    @include mobile {
      font-size: 0.625rem;
    }
  }

  &-legend {
    background-color: $white-true;
    margin-top: 10px;
    text-align: center;
    filter: drop-shadow(0px 0px 16.5263px rgba(0, 0, 0, 0.25));
    padding: 10px;
    border-radius: 16px;

    &__title {
      font-weight: 700;
      font-size: 19px;
      margin-top: 5px;
      line-height: 1;
    }

    &__subtitle {
      font-size: 10px;
      line-height: 1;
    }

    &__colors {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
    }

    &__color {
      display: flex;
      font-size: 10px;
      margin-right: 8px;
      align-items: center;

      &-item {
        @include round(24px);
        flex-shrink: 0;
        margin-right: 8px;

        &.red {
          background-color: $c-red;
        }

        &.blue {
          background-color: $c-blue;
        }

        &.green {
          background-color: $c-green;
        }
      }
    }
  }
}
