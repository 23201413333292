.article {
    &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 100%;
        max-width: 648px;
        width: 100%;
        aspect-ratio: 648/328;
        padding: 34px 0 26px;
        border-radius: 26px;
        cursor: pointer;
        background-size: cover;
        background-position: center;

        @include tablet {
            max-width: 416px;
            aspect-ratio: 416/210;
        }

        @include mobile {
            max-width: 323px;
            aspect-ratio: 323/248;
        }

        &.card-avito {
            background-color: #04e061;
            background-image: img("bgn-avito.svg");
        }

        &.card-telegram {
            background-color: $c-blue;
            background-image: img("bgn-telegram.svg");
        }
    }

    &-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            max-width: 145px;
            width: 30%;
            aspect-ratio: 145/140;
            border-radius: 38px;
            box-shadow: 4px 6px 27px rgba(0, 0, 0, 0.16);
            object-fit: cover;
            object-position: center;

            @include tablet {
                max-width: 94px;
                aspect-ratio: 94/90;
                border-radius: 24px;
                box-shadow: 3px 4px 17px rgba(0, 0, 0, 0.16);
            }

            @include mobile {
                max-width: 104px;
                width: 100%;
                aspect-ratio: 104/99;
                border-radius: 27px;
                box-shadow: 3px 4px 19px rgba(0, 0, 0, 0.16);
            }
        }
    }

    &-text {
        margin-top: 22px;
        font-size: 2rem;
        line-height: 1.22;
        text-align: center;

        @include low-desktop {
            @include css-lock(1.375, 10, $tablet-width, $desktop-width);
        }

        @include tablet {
            margin-top: 14px;
            font-size: 1.375rem;
            line-height: 1.11;
        }

        @include mobile {
            margin-top: 16px;
        }
    }
}
