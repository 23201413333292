$b: '.accordion';

@mixin opened {
  #{$b}.opened & {
    @content;
  }
}

#{$b} {
  cursor: pointer;
  position: relative;
  padding: 24px;
  background-color: #FAFAFA;
  border: 1px solid $c-lightgray;
  border-radius: 24px;

  &.opened {
    background-color: $white-true;

    &::after {
      transform: rotate(180deg);
    }
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    background-image: img('accordion-arrow.svg');
    position: absolute;
    right: 26px;
    top: 26px;
    transform: rotate(0deg);
    transition: 0.5s;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    transition: 0.5s;
    padding-right: 24px;

    @include tablet {
      font-size: 12px;
    }

    @include opened {
      margin-bottom: 12px;
    }
  }

  &__content {
    overflow: hidden;
    transition: 0.5s ease;
    color: darkgray;
    font-size: 20px;
    line-height: 1;

    @include tablet {
      font-size: 12px;
    }
  }
}
