@use 'scss-reset/reset';

@font-face {
  font-family: "Aeroport";
  src: local("Aeroport Regular"), local("Aeroport-Regular"), url("Aeroport.woff2") format("woff2"),
    url("Aeroport.woff") format("woff"), url("Aeroport.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: auto !important;
}

body {
  font-family: "Aeroport", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  scroll-behavior: auto !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  min-height: 1px;
  overflow: hidden;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

@import "scss/settings";
@import "scss/_mixins";
@import "scss/blocks/blocks";
@import "scss/sections/intro";
@import "scss/sections/analytics";
@import "scss/sections/progress";
@import "scss/sections/map";
@import "scss/sections/info";
@import "scss/sections/cta";
@import "scss/sections/articles";
@import "scss/sections/article";
@import "scss/sections/footer";
@import "scss/sections/gauge";
@import "scss/sections/median";
@import "scss/sections/header";
@import "scss/sections/mui-autocomplete";
@import "scss/sections/hero";
@import "scss/sections/about";
@import "scss/sections/how-to";
@import "scss/sections/more";
@import "scss/blocks/btn";
@import "scss/blocks/modal";
@import "scss/blocks/form-input";
@import "scss/blocks/call-form";
@import "scss/blocks/accordion";
@import "scss/blocks/filter";
@import "scss/blocks/tabs";
@import "scss/blocks/table";
@import "scss/blocks/form";
@import "scss/utils/hidden";
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/effect-cards';
