$b: '.tabs';

#{$b} {
  &__wrapper {
    display: flex;
    gap: 20px;

    @include tablet {
      display: block;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(50% - 10px);
  }

  &__slider {
    width: calc(50% - 10px);
    border-radius: 15px;
    overflow: hidden;
    background-color: #f7f8f9;
    display: flex;

    @include tablet {
      width: 100%;
      gap: 9px;
      background-color: transparent;

      &.tab {
        display: flex;
      }
    }

    @include mobile {
      &.tab {
        display: none;
      }
    }

    @include mobile {
      flex-direction: column;
    }

    &-container {
      display: flex;
      flex-direction: column;

      @include tablet {
        width: calc((100% / 3) - 9px * 2 / 3);

         & > * {
           text-align: left;
         }
      }

      @include mobile {
        display: flex !important;
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    &-img {
      width: 100%;
      flex-grow: 1;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;

      @include tablet {
        aspect-ratio: 324 / 278;
        background-color: #f7f8f9;
        border-radius: 15px;
      }

      @include mobile {
        width: 100%;
        background-size: auto 90%;
        background-position-y: bottom;

        .swiper-slide & {
          background-size: 100% auto;
        }
      }

      &.flex {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title {
        padding: 10px 20px 0;
        margin-bottom: -36px;
        font-weight: 700;

        @include mobile {
          font-size: 11px;
        }

        ol {
          font-weight: 400;
          list-style: decimal;

          li {
            display: inline-block;
          }
        }
      }
    }

    &-subtitle {
      font-size: 19px;
      margin-top: 20px;
      margin-bottom: 20px;

      @include mobile {
        text-align: left;
        margin-top: 16px;
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: auto !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      box-shadow: 1px 1px 6px rgba($black-true, 0.25);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white-true;
      transition: 0.2s;

      &:active {
        scale: 0.95;
      }

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: img("slider-arrow.svg");
      }
    }

    .swiper-button-next {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__item {
    cursor: pointer;
    padding: 32px 40px;
    border: 1px solid $c-blue;
    border-radius: 15px;
    text-align: left;
    font-weight: 700;

    &.active {
      background-color: $c-blue;
      color: $white-true;
    }
  }

  &__list {
    @include tablet {
      display: flex !important;
      gap: 9px;
      align-items: flex-start;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  &__content {
    transform: translateY(2px);
    background-size: 80%;
    background-position-x: center;
    background-repeat: no-repeat;
    background-position-y: 28px;
    margin-top: 50px;
    border-radius: 16px 16px 0 0;
    width: 343px;
    background-color: $white-true;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    box-shadow: 1px 4px 13px rgba($black-true, 0.25);
    padding: 27px 29px;

    @include tablet-only {
      width: 163px;
      padding: 12px;
      margin-top: 10px;
    }

    @include mobile {
      width: 300px;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 32px;
      line-height: 1;

      @include tablet-only {
        font-size: 8px;
        margin-bottom: 10px;
      }
    }

    &-row {
      display: flex;
      position: relative;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 32px;

        @include tablet-only {
          margin-bottom: 16px;
        }
      }

      &:nth-child(2)::after,
      &:nth-child(3)::after {
        @include size(14px);
        background-size: cover;
        position: absolute;
        left: 32px;
        transform: translateX(-50%) translateY(-50%);
        content: '';
        bottom: -28px;

        @include tablet-only {
          left: 11%;
          bottom: -45%;
        }
      }

      &:nth-child(2)::after {
        background-image: img('cross.svg');
      }

      &:nth-child(3)::after {
        width: 16px;
        height: 9px;
        background-image: img('equals.svg');

        @include tablet-only {
          bottom: -29%;
        }
      }
    }

    &-text {
      text-align: left;

      @include tablet-only {
        font-size: 8px;
      }

      i {
        color: $c-violet;
        display: block;
        border-top: 2px solid;
      }
    }

    &-img {
      @include round(64px);
      @include center-flex;
      flex-shrink: 0;
      margin-right: 20px;

      @include tablet {
        @include size(34px);
      }

      @include mobile {
        @include size(64px);
      }

      svg {
        width: 32px;

        @include tablet {
          width: 20px;
        }

        @include mobile {
          width: 32px;
        }
      }

      &.c-purple {
        background-color: $c-violet;
      }

      &.c-blue {
        background-color: $c-blue;
      }

      &.c-green {
        background-color: #04E061;
      }
    }
  }
}
