.footer {
    &-links {
        display: flex;
        justify-content: center;
        gap: 16px;
        padding: 1em;
        font-size: 1.125rem;
        line-height: 1.29;

        @include low-desktop {
            @include css-lock(0.75, 6, $tablet-width, $desktop-width);
        }

        @include tablet {
            @include css-lock(0.5, 4, $mobile-width, $tablet-width);
            flex-wrap: wrap;
            gap: 20px;
            line-height: 1.29;
        }

        @include mobile {
            justify-content: flex-start;
            padding: 20px 0;
            gap: 9px 6px;
            font-size: 0.5rem;
        }

        &-container {
            background: #f4f5f6;
            border-bottom: 2px solid #d9d9d9;

            @include mobile {
                padding: 0 54px;
            }
        }

        a {
             color: $c-blue;
             white-space: nowrap;
        }

        li:last-child {
            font-weight: 700;
        }
    }

    &-credentials {
        margin-top: 22px;
        font-size: 0.875rem;
        line-height: 1.29;
        color: #b0b0b0;
        font-family: Arial, sans-serif;
        text-align: center;

        @include low-desktop {
            @include css-lock(0.625, 5, $tablet-width, $desktop-width);
        }

        @include tablet {
            @include css-lock(0.375, 3, $mobile-width, $tablet-width);
            line-height: 1.29;
        }

        @include mobile {
            margin-top: 17px;
            padding: 0 54px;
            font-size: 0.375rem;
            text-align: left;
        }

         a {
             text-decoration: underline;
         }
    }
}

footer {
    margin-top: 105px;
    margin-bottom: 34px;

    @include tablet {
        margin-top: 76px;
        margin-bottom: 30px;
    }

    @include mobile {
        margin-top: 50px;
        margin-bottom: 16px;
    }
}
