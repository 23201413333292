.map {
  width: 100%;
  height: 545px;

  @include tablet {
    height: 360px;
  }

  @include mobile {
    height: 228px;
  }

  &-container {
    position: relative;
    margin: 16px auto auto;
    padding: 0 60px;

    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 64px;
      padding: 0 40px;
    }

    @include mobile {
      margin: 26px auto auto;
      padding: 0 28px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @include tablet {
      max-width: 870px;
    }

    @include mobile {
      width: 100%;
    }
  }
}
