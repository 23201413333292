.analytics {
  &-section {
    max-width: 1640px;
    margin: 29px auto auto;

    @include tablet {
      margin-top: 29px;
    }

    @include mobile {
      margin-top: 25px;
    }
  }

  &-form {
    position: relative;
    padding: 50px 130px 30px;
    background: $c-blue;
    max-width: 1430px;
    margin: 0 auto;

    &::before {
      content: '';
      z-index: -1;
      background-color: $c-blue;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
    }

    @include tablet {
      margin: 0 34px;
    }

    @include mobile {
      margin-left: 0;
      margin-right: 0;
      padding: 30px 70px 18px;
    }

    form {
      @include css-lock(1, 9, $tablet-width, $desktop-width);
      position: relative;
      max-width: 1129px;
      width: 80%;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin: 0 auto;
      padding: 33px 34px 35px 36px;
      border-radius: 6px;
      box-shadow: 4px 12px 41px rgba(0, 0, 0, 0.05);
      background: #fff;
      font-size: 1.5625rem;
      line-height: 1.28;

      @include tablet {
        @include css-lock(0.625, 6, $mobile-width, $tablet-width);
        max-width: 747px;
        gap: 19px;
        padding: 21px 22px 23px 23px;
      }

      @include mobile {
        flex-direction: column;
        gap: 6px;
        width: 100%;
        padding: 18px 20px;
        font-size: 0.625rem;
      }

      .filter:first-child .search {
        margin-top: 8px;

        @include tablet {
          margin-top: 4px;
        }

        @include mobile {
          margin-top: 2px;
        }
      }
    }
  }
}
