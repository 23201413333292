.visible {
  &-mobile {
    display: none;

    @include mobile {
      display: block;
    }

    &-i {
      display: none !important;

      @include mobile {
        display: block !important;
      }
    }
  }

  &-tablet {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.hidden {
  &-mobile {
    @include mobile {
      display: none;
    }

    &-i {
      @include mobile {
        display: none !important;
      }
    }
  }

  &-tablet {
    @include tablet {
      display: none;
    }
  }
}
