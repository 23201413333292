.about {
  padding-top: 48px;
  text-align: center;

  @include mobile {
    padding-top: 0;
  }

  &__intro {
    margin: 0 auto 56px;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    .btn {
      min-height: 42px;
      font-size: 14px;
    }
  }

  &__title {
    font-size: 45px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 26px;
    display: inline-block;

    @include tablet {
      font-size: 24px;
      margin-bottom: 16px;
    }

    @include mobile {
      font-size: 15px;
    }

    &-container {
      width: max-content;
      margin: 0 auto;

      hr {
        height: 5px;
        background-color: $c-violet;
        transform: translateY(-100%);
        border-radius: 100vw;
        position: relative;
        z-index: -1;

        @include mobile {
          height: 2px;
        }
      }
    }
  }

  &__subtitle {
    font-size: 45px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 24px;

    @include tablet {
      font-size: 24px;
    }

    @include mobile {
      font-size: 28px;
      margin-bottom: 16px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__tabs {
    margin-top: 48px;
    margin-bottom: 97px;

    @include tablet {
      margin-bottom: 20px;
    }

    @include mobile {
      margin-bottom: 32px;
    }

    .btn {
      min-height: 42px;
      font-size: 24px;

      @include tablet {
        font-size: 14px;
      }
    }
  }

  &__description {
    font-size: 26px;
    width: 60%;
    margin: 0 auto 20px;

    @include tablet {
      font-size: 14px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mobile {
      width: 90%;
    }

    b {
      color: $c-violet;
    }
  }
}
