.wrapper {
    min-height: 100%;
    overflow: hidden;
}

.container {
    max-width: #{$desktop-width}px;
    margin: 0 auto;
    padding: 0 100px;

    @include tablet {
        padding: 0 80px;
    }

    @include mobile {
        padding: 0 16px;
    }
}

.disclaimer {
    text-align: left;
    font-size: 20px;
    color: #b0b0b0;
    line-height: 0.9;
    margin-top: 22px;

    @include low-desktop {
        font-size: 13px;
        margin-top: 14px;
    }

    @include mobile {
        margin-top: 19px;
        font-size: 10px;
    }
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    border-radius: 6px;
    z-index: 9999;
}

.hint {
    min-width: 0;
    left: 0;
    top: 36px;
    transform: none !important;

    &__item:not(:last-child) {
        margin-bottom: 8px;
    }

  &__title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 1em;
  }

  &__subtitle {
    margin-bottom: 12px;

    b {
      display: block;
      font-weight: 700;
    }
  }

  &__description {
    font-size: 13px;
    line-height: 1.2;

    &-title {
      margin-bottom: 1em;
      font-weight: 700;
    }
  }

  &__list {
    list-style: disc;
    padding-left: 16px;
  }
}
