.table {
  display: table;
  border-radius: 22px;
  overflow: hidden;
  width: 100%;

  &__wrapper {
    background-color: $white-true;
    border-radius: 44px;
    padding: 24px;
  }

  &__row {
    display: table-row;
  }

  &__item {
    background-color: #f2f2f2;
    padding: 20px 24px;
    display: table-cell;
    border: 4px solid $white-true;
    vertical-align: middle;

    @include low-desktop {
      @include css-lock(0.75, 6, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(0.5, 4, $mobile-width, $tablet-width);
    }

    &-list {
      text-align: left;
      list-style: disc;
    }

    b {
      font-weight: 700;
      display: block;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 20px;

    @include tablet {
      @include css-lock(0.7, 3, $mobile-width, $tablet-width);
    }
  }
}
