$b: '.progress';

#{$b} {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);

  &-bar {
    position: relative;

    &__labels {
      display: flex;
      justify-content: space-between;
      line-height: 0.9;
    }

    &__max {
      text-align: right;
    }

    &__fill {
      position: relative;
      width: 100%;
      height: 16px;
      margin-top: 4px;
      margin-bottom: 6px;
      background: linear-gradient(270deg, #05FF00 0%, #ECFEEC 110.58%);
    }

    &__mark {
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);

      &__arrow {
        @include size(0);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid $black-true;
        transform: translateY(-6px);

        &.top {
          transform: translateY(-16px);
        }
      }

      &__line {
        width: 1px;
        height: 16px;
        transform: translateX(5px);
        background-color: $black-true;
      }
    }
  }

  &-bar__fill:hover {
    .info-hint__text {
      display: block;
      transform: none !important;
      left: 0;
      top: 35px;
    }

    #{$b}-bar__mark._tooltip::before {
      @include size(100%);
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, 80%);
      background: img("arrow.svg") no-repeat center;
    }
  }
}
