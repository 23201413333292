$b: '.form-input';

#{$b} {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__el {
    padding: 24px 20px 8px;
    width: 100%;
    border: 2px solid #E4E4E4;
    border-radius: 8px;
    font-size: 16px;
    position: relative;
    z-index: 2;
    background-color: transparent;

    &:focus {
      border-color: rgba($c-blue, 0.5);
    }

    &::placeholder {
      opacity: 0;
      transition: 0.25s;
    }

    &:focus,
    &._filled {
      &::placeholder {
        opacity: 1;
      }
    }

    @include mobile {
      padding: 16px 10px 4px;
      font-size: 14px;
    }
  }

  &__label {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.25s;
    z-index: 1;

    @include mobile {
      left: 10px;
      font-size: 12px;
    }
  }

  &__el:focus + &__label,
  &__el._filled + &__label {
    top: 8px;
    font-size: 12px;
    transform: translateY(0);

    @include mobile {
      font-size: 10px;
      top: 6px;
    }
  }

  &._error {
    color: $c-red;

    #{$b}__el {
      border: 2px solid rgba($c-red, 0.5);
    }
  }
}
