$b: '.form';

#{$b} {
  &-column {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;

    @include tablet {
      gap: 7.28px;
    }

    @include mobile {
      gap: 6px;
    }
  }

  &-hint {
    max-width: 80%;
    margin: 30px auto auto;
    text-align: center;
    font-size: 1.1875rem;
    line-height: 1.05;
    color: white;

    @include low-desktop {
      @include css-lock(0.75, 7, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(0.5625, 3, $mobile-width, $tablet-width);
      margin-top: 22px;
    }

    @include mobile {
      margin-top: 20px;
      font-size: 0.5625rem;
    }
  }

  &-item {
    flex-direction: column;
    opacity: 40%;
    transition: 0.5s;
    width: 100% !important;
  }

  &-slider {
    align-items: center;

    &__container {
      display: flex;

      &::before,
      &::after, {
        content: '';
        transition: 1s;
      }

      &.left {
        &::before {
          flex-grow: 1;
        }
      }

      &.right {
        &::after {
          flex-grow: 1;
        }
      }
    }

    &__wrapper {
      margin: 0 !important;
      max-width: 642px;

      @include tablet {
        max-width: 342px;
      }

      @include mobile {
        max-width: 100%;
      }
    }
  }
}

.swiper-slide-active {
  #{$b}-item {
    opacity: 1;
  }
}

.swiper-slide {
  @include center-flex;
  display: flex !important;
}
