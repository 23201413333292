@mixin low-desktop() {
    @media screen and (max-width: #{$desktop-width}px) {
        @content;
    }
}

@mixin tablet() {
    @media screen and (max-width: #{$tablet-width - 1}px) {
        @content;
    }
}

@mixin tablet-only() {
  @media screen and (max-width: #{$tablet-width - 1}px) and (min-width: #{$mobile-width}px) {
    @content;
  }
}

@mixin mobile() {
    @media screen and (max-width: #{$mobile-width - 1}px) {
        @content;
    }
}

@mixin css-lock($rem, $num, $min, $max, $property: font-size) {
    #{$property}: calc(#{$rem}rem + #{$num} * (100vw - (#{$min} * 1px)) / (#{$max} - #{$min}));
}

@mixin full-pos($pos) {
    top: $pos;
    bottom: $pos;
    left: $pos;
    right: $pos;
}

@mixin full-fix($pos) {
    @include full-pos($pos);
    position: fixed;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin round($size) {
  @include size($size);
  border-radius: 50%;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@function img($url) {
  @return url('../../../public/#{$url}');
}
