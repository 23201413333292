$desktop-width: 1640;
$tablet-width: 1024;
$mobile-width: 768;

$black-true: #000;
$white-true: #fff;

$c-violet: #965eeb;
$c-blue: #00aaff;
$c-green: #04e061;
$c-red: #ff4053;
$c-gray: #cbcbcb;
$c-lightgray: #edeff0;
