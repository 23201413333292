$b: '.call-form';

#{$b} {
  &__title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;

    @include tablet {
      font-size: 24px;
      margin-bottom: 18px;
    }

    @include mobile {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__description {
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;

    @include mobile {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  &__city {
    margin-bottom: 8px;
    position: relative;
  }

  &__dropdown {
    box-shadow: 1px 1px 6px rgba($black-true, 0.25);
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white-true;
    border-radius: 5px;
    transform: translateY(100%);
    border: 2px solid #E4E4E4;
    z-index: 100;

    &-item button {
      font-size: 16px;
      padding: 12px 8px;
      transition: 0.25s;
      width: 100%;
      text-align: left;
      border-radius: 1px;

      @include mobile {
        font-size: 12px;
      }

      &:hover {
        background-color: rgba($c-blue, 0.1);
      }
    }
  }
}
