.intro {
  &-section {
    margin-top: 97px;
    padding: 0 50px;

    @include tablet {
      margin-top: 64px;
    }

    @include mobile {
      margin-top: 73px;
      padding: 0 10px;
    }
  }

  &-title {
    font-size: 5rem;
    line-height: 0.94;
    letter-spacing: -0.005em;
    text-align: center;

    @include low-desktop {
      @include css-lock(3.3125, 27, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(2.25, 17, $mobile-width, $tablet-width);
    }

    @include mobile {
      font-size: 2.25rem;
    }

    span {
      position: relative;
      white-space: nowrap;
      color: $white-true;

      &::before {
        content: "";
        display: block;
        width: 105%;
        height: 0.875em;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5%;
        margin-left: -52.5%;
        background: $c-violet;
        border-radius: 60px;
        z-index: -1;

        @include mobile {
          width: 105%;
          height: 0.913em;
          margin-left: -52%;
        }
      }
    }
  }

  &-text {
    margin-top: 50px;
    font-size: 2rem;
    line-height: 1.16;
    text-align: center;
    white-space: pre-line;

    @include low-desktop {
      @include css-lock(1.3125, 11, $tablet-width, $desktop-width);
    }

    @include tablet {
      @include css-lock(0.8125, 8, $mobile-width, $tablet-width);
      margin-top: 28px;
    }

    @include mobile {
      margin-top: 25px;
      font-size: 0.8125rem;
    }

    span {
      white-space: nowrap;
    }
  }
}
