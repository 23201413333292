.articles {
    &-section {
        margin-top: 75px;
        letter-spacing: -1px;
        text-align: center;

        @include tablet {
            margin-top: 45px;
        }

        @include mobile {
            margin-top: 75px;
        }
    }

    &-title {
        font-size: 3rem;
        line-height: 1;

        @include low-desktop {
            @include css-lock(1.875, 18, $tablet-width, $desktop-width);
        }

        @include tablet {
            font-size: 1.875rem;
            line-height: 1;
            letter-spacing: -1px;
        }
    }

    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 38px;
        margin-top: 40px;

        @include tablet {
            margin-top: 64px;
            gap: 12px;
        }

        @include mobile {
            margin-top: 77px;
            gap: 28px;
            flex-direction: column;
        }
    }
}
