.more {
  padding-top: 66px;

  @include tablet {
    padding-top: 20px;
  }

  &__title {
    font-size: 45px;
    text-align: center;
    margin: 0 auto;

    @include tablet {
      font-size: 24px;
    }
  }

  &__accordions {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tablet {
      margin-top: 24px;
      gap: 8px;
    }

    @include mobile {
      margin-top: 36px;
      gap: 12px;
    }
  }

  &__list {
    line-height: 1;
    padding-left: 1em;

    &-item {
      list-style-type: disc;
    }
  }
}
