.filter {
    width: 100%;
    display: flex;
    flex-direction: column;

    .search {
        width: 100%;
        margin-top: 6px;
        border-radius: 6px;
        background-color: rgba(241, 241, 241, 0.5);
        box-shadow: inset 0 3px 51px rgba(0, 0, 0, 0.1);

        @include tablet {
            border-radius: 4px;
            margin-top: 3.15px;
        }

        @include mobile {
            margin-top: 1.43px;
            border-radius: 2px;
        }

        input::placeholder {
            color: $c-gray;
        }
    }
}
