$b: '.btn';

#{$b} {
  @include center-flex;
  padding: 16px 24px 17px;
  margin: 0 auto;
  border: none;
  border-radius: 6px;
  font-size: 1.5625rem;
  line-height: 1.28;
  cursor: pointer;

  @include low-desktop {
    @include css-lock(1, 9, $tablet-width, $desktop-width);
  }

  @include tablet {
    @include css-lock(0.8125, 3, $mobile-width, $tablet-width);
    padding: 10px 15px 11px;
    border-radius: 4px;
  }

  @include mobile {
    padding: 7px 0 8px;
    border-radius: 2px;
    font-size: 0.75rem;
    line-height: 1.21;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &-search {
    height: 53px;
    width: 100%;
    background: $c-blue;
    font-size: inherit !important;
    line-height: inherit !important;
    color: #fff;

    @include tablet {
      height: 30px;
    }

    @include mobile {
      padding: 5px 0 6px;
    }

    &-wrapper {
      @include size(100%);
      display: flex;
      align-items: flex-end;

      @include mobile {
        margin-top: 16px;
        height: 24px;
      }
    }

    &#{$b}-reset {
      background: $c-gray;
      color: $white-true;
    }
  }

  &-call {
    margin-top: 44px;
    background: $c-blue;
    box-shadow: 0 2px 8px rgba($black-true, 0.16);
    color: $white-true;
    transition: 0.25s;

    @include tablet {
      margin-top: 28px;
    }

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-place {
    margin-top: 48px;
    background: rgba(242, 239, 233, 0.19);
    box-shadow: 0 2px 8px rgba($black-true, 0.16);

    @include tablet {
      margin-top: 32px;
    }

    @include mobile {
      width: 100%;
      margin-top: 10px;
    }
  }

  a {
    display: block;
    width: 100%;
  }
}
