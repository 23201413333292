$b: '.header';

#{$b} {
  padding: 24px 0;
  background-color: $black-true;
  margin-bottom: auto;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
  }

  &__logo {
    width: 133px;

    @include mobile {
      width: 102px;
    }

    &-img {
      display: block;
      height: auto;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    &-items {
      display: flex;
      align-items: center;
      gap: 52px;
      color: $white-true;
      font-size: 16px;
    }
  }

  &__menu {
    &-btn {
      width: 22px;
      height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.active {
        span:first-child {
          top: -1px;
          left: 2px;
          transform: rotate(45deg);
        }

        span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        span:last-child {
          top: 1px;
          left: 2px;
          transform: rotate(-45deg);
        }
      }

      &-item {
        height: 2px;
        border-radius: 100vw;
        background-color: $white-true;
        transition: 0.5s;
        position: relative;
        transform-origin: left center;
        width: 100%;
      }
    }

    &-el {
      @include full-fix(0);
      top: 82px;
      background-color: $black-true;
      z-index: 1000;
      transform: translateX(100%);
      transition: 0.5s;
      opacity: 0.8;

      @include mobile {
        top: 72px;
      }

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &-nav {
      padding-left: 15px;
      padding-top: 31px;
      color: $white-true;

      &-item {
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 21px;
        }
      }
    }
  }
}
