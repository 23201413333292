.hero {
  background: linear-gradient(180deg, #965EEB 0%, #6A43A5 100%);
  overflow: hidden;

  @include mobile {
    background: none;
    position: relative;

    &::before {
      @include full-pos(0);
      aspect-ratio: 430 / 220;
      bottom: auto;
      content: '';
      background: linear-gradient(180deg, #965EEB 0%, #6A43A5 100%);
      position: absolute;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include tablet {
      padding: 50px 0;
    }

    @include mobile {
      flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }

  &__decor {
    width: percentage(561 / $desktop-width);
    height: auto;
    position: relative;
    flex-shrink: 0;
    align-self: flex-end;
    margin-right: 60px;

    @include low-desktop {
      width: percentage(491 / $desktop-width);
      margin-right: 0;
    }

    @include tablet {
      position: absolute;
      align-self: center;
      right: 0;
      bottom: 0;
      width: percentage(492 / $tablet-width);
    }

    @include mobile {
      position: static;
      width: percentage(324 / 430);
    }
  }

  &__title {
    padding-left: 97px;
    color: $white-true;
    font-size: 60px;
    line-height: 1;
    width: 48%;
    font-weight: 700;

    @include low-desktop {
      font-size: 48px;
      width: 50%;
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 77px;
    }

    @include tablet {
      font-size: 32px;
    }

    @include mobile {
      font-size: 28px;
      color: $black-true;
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }
}
