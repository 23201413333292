.gauge {
  text-align: center;

  &__table {
    background-color: $c-violet;
    background-image: img('table-bg.svg');
    background-size: cover;
    padding: 64px 0;
  }
}
