.cta {
    &-section {
        margin-top: 58px;
        line-height: 0.77;
        text-align: center;

        @include tablet {
            margin-top: 36px;
        }

        @include mobile {
            margin-top: 44px;
        }
    }

    &-title {
        font-size: 3rem;

        @include low-desktop {
            @include css-lock(2, 16, $tablet-width, $desktop-width);
        }

        @include tablet {
            @include css-lock(1.875, 2, $mobile-width, $tablet-width);
        }

        @include mobile {
            font-size: 1.875rem;
            line-height: 1;
        }
    }

    &-text {
        margin-top: 30px;
        font-size: 2rem;
        line-height: 1.15;

        @include low-desktop {
            @include css-lock(1.3125, 11, $tablet-width, $desktop-width);
        }

        @include tablet {
            @include css-lock(0.8125, 8, $mobile-width, $tablet-width);
            margin-top: 24px;
        }

        @include mobile {
            margin-top: 35px;
            font-size: 0.8125rem;
            line-height: 1.15;
        }
    }
}
